import React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";

import { FaEnvelope, FaLock } from "react-icons/fa";

const LoginPage = () => (
    <Layout>
        <SEO title="Sign in" />
        <section className="hero is-fullheight-with-navbar">
            <div className="hero-body container has-text-centered">
                <div className="columns">
                    <div className="column">
                        <h1 className="title">Sign In</h1>
                        <h2 className="subtitle">Access your Galaxy Pro Dashboard</h2>
                        <p>
                            Note, to access your personal Galaxy Pro instance you will need to use the associated link.
                        </p>
                    </div>
                    <div className="column">
                        <div className="field">
                            <p className="control has-icons-left has-icons-right">
                                <input className="input" type="email" placeholder="Email" />
                                <span className="icon is-small is-left">
                                    <FaEnvelope />
                                </span>
                            </p>
                        </div>
                        <div className="field">
                            <p className="control has-icons-left">
                                <input className="input" type="password" placeholder="Password" />
                                <span className="icon is-small is-left">
                                    <FaLock />
                                </span>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </Layout>
);

export default LoginPage;
